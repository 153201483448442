import React from "react";
import { Routing } from "./Routes/Routing";

function App() {
  return (
    <div>
      <Routing />
    </div>
  );
}

export default App;
