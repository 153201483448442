import moment from "moment";
import React from "react";

export const ProfileDetails = ({ filteredDocs, profile }) => {
  const handlePrint = () => {
    window.print(1);
  };

  return (
    <div>
      {profile && (
        <div className="">
          {filteredDocs.length === 0 ? (
            <div className="my-10">
              <p>No documents rendered</p>
            </div>
          ) : (
            <div className="flex flex-col h-full lg:px-4">
              <div className="flex flex-col lg:gap-2">
                <img
                  src={filteredDocs[0].user_data?.image}
                  alt="User"
                  className="w-[80px] h-[80px] lg:w-[100px] lg:h-[100px] mb-2 rounded-full"
                />

                <div className="flex flex-col">
                  <p className="text-[12px] lg:text-[14px]">
                    <span className="font-semibold">First Name:</span>{" "}
                    {filteredDocs[0].user_data.first_name}{" "}
                  </p>
                  <p className="text-[12px] lg:text-[14px]">
                    <span className="font-semibold">Last Name:</span>{" "}
                    {filteredDocs[0].user_data.last_name}
                  </p>
                  <p className="text-[12px] lg:text-[14px]">
                    <span className="font-semibold">Date of Birth:</span>{" "}
                    {moment(filteredDocs[0]?.json?.date_of_birth).format(
                      "MMMM DD, YYYY"
                    )}
                  </p>

                  <p className="text-[12px] mb-2 lg:text-[14px]">
                    <span className="font-semibold">Email:</span>{" "}
                    {filteredDocs[0]?.user_data?.email}
                  </p>
                </div>
              </div>

              <h3 className="mt-2 text-[14px] lg:text-[16px] font-bold">
                Documents
              </h3>

              <table className="w-full">
                <tbody>
                  {filteredDocs.map((doc, index) => (
                    <tr
                      key={index}
                      className="flex flex-col mb-6 border border-[#245FAF] p-4 rounded-[10px] mt-4"
                    >
                      <div className="flex flex-col lg:flex-row">
                        <td className="text-[13px] ">
                          <span className="font-semibold">Name:</span>{" "}
                          {doc.document_data?.document_type}
                        </td>
                        <td className="text-[13px]">
                          <span className="font-semibold">ID Number:</span>{" "}
                          {doc.json.id_number}
                        </td>
                        <td className="text-[13px]">
                          <span className="font-semibold">Description:</span>{" "}
                          {doc.document_data?.description}
                        </td>
                        <td className="text-[13px]">
                          <span className="font-semibold">Amount:</span> ₦
                          {doc.document_data?.price.toLocaleString()}
                        </td>
                      </div>
                      <div className="flex flex-col">
                        <td className="text-[13px]">
                          <span className="font-semibold">Image:</span>{" "}
                          <img
                            src={doc.doc_image ? doc.doc_image : "No Image"}
                            alt="doc_image"
                            className="w-full"
                          />
                        </td>
                        <td className="text-[13px]">
                          <span className="font-semibold">File:</span>{" "}
                          <img
                            src={doc.doc_file ? doc.doc_file : "No file"}
                            alt="doc_file"
                          />
                        </td>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>

              <button
                onClick={handlePrint}
                className="font-bold bg-[#245FAF] text-white py-2 rounded-[30px]"
              >
                Download/Print
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
