import React from "react";
import qrImage from "../../assets/qr.jpg";
import logo from "../../assets/logo.png";

export const Home = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${qrImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        height: "100vh",
      }}
      className="p-4"
    >
      <img src={logo} alt="logo" className="w-[100px] lg:w-[120px]" />
    </div>
  );
};
