import React from "react";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { QRValidation } from "../pages/QRValidation/QRValidation";
import { Home } from "../pages/Home/Home";

export const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/qr-code/v1/validate/:id" element={<QRValidation />} />
      </Routes>
    </BrowserRouter>
  );
};
