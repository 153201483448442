import axios from "axios";
import { baseUrl } from "./ApiConfig";

// let token = JSON.parse(localStorage.getItem("DannonUser"))?.access;
// console.log("QRtoken", token);

export const GetProfileqrApi = (id) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/v1/api/qr/render/profile/${id}/`,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      if (error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else if (error.message) {
        throw new Error(error.message);
      }
    });
};

export const RequestOTPPinApi = (id) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/v1/api/qr/request/pin/${id}/`,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      if (error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else if (error.request) {
        throw new Error(error.message);
      } else {
        throw new Error(error.message);
      }
    });
};

export const VerifyOTPApi = (id, Payload) => {
  console.log("VerifyOTPApi", Payload);

  let data = JSON.stringify(Payload);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/v1/api/qr/verify/pin/${id}/`,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      if (error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else if (error.request) {
        throw new Error(error.message);
      }
    });
};


