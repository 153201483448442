import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  GetProfileqrApi,
  RequestOTPPinApi,
  VerifyOTPApi,
} from "../../Utils/ApiCalls";
import { showToast } from "../../Toastify/Toast";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";
import logo from "../../assets/logo.png";
import otpImage from "../../assets/otp.png";
import Spinner from "../../components/Spinner";
import moment from "moment";
import { ProfileDetails } from "../ProfileDetails";

const OTP_LENGTH = 6;

export const QRValidation = () => {
  const { id } = useParams();

  const [isVerified, setIsVerified] = useState(false);
  const [profile, setProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enterLoading, setEnterLoading] = useState(false);
  const [Docs, setDocs] = useState([]);
  const [otp, setOtp] = useState(Array(OTP_LENGTH).fill(""));
  const [currentIndex, setCurrentIndex] = useState(0);

  const ProfileApi = async () => {
    try {
      setLoading(true);
      const res = await GetProfileqrApi(id);
      console.log("ProfileApi", res);
      if (res.status === 200) {
        setIsVerified(true);
        setDocs(res?.data);
      } else if (res.status === 400) {
        setIsVerified(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      setIsLoading(true);
      const res = await RequestOTPPinApi(id);
      console.log("handleResendCode", res);
      if (res.status === 200) {
        showToast({
          message: "New OTP sent",
          type: "success",
        });
      }
    } catch (error) {
      showToast({
        message: "Require OTP",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (element, index) => {
    const inputValue = element.value;

    // Allowing alphanumeric characters
    if (!/^[0-9a-zA-Z]*$/.test(inputValue)) return;

    setOtp([...otp.map((d, i) => (i === index ? inputValue : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleOTPInput = async () => {
    try {
      setEnterLoading(true);
      const otpString = otp.join("");

      const res = await VerifyOTPApi(id, { pin: otpString });
      console.log("HandleOTPInput", res);

      if (res?.status === 200) {
        showToast({
          type: "success",
          message: res.data.message,
        });
        setIsVerified(true);
      }
    } catch (error) {
      showToast({
        type: "error",
        message: error.message,
      });
    } finally {
      setEnterLoading(false);
    }
  };

  const handleView = async () => {
    setProfile(true);
  };

  const showNextImage = () => {
    if (Docs.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Docs.length);
    }
  };

  const showPrevImage = () => {
    if (Docs.length > 0) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? Docs.length - 1 : prevIndex - 1
      );
    }
  };

  const filteredDocs = Docs.filter((doc) => doc.doc_image !== null);

  useEffect(() => {
    ProfileApi();
  }, []);

  return (
    <div className="p-4">
      <div>
        <Link to="/">
          <img src={logo} alt="logo" className="w-[100px] lg:w-[120px]" />
        </Link>
      </div>
      <div className="flex items-center justify-center">
        <div className="lg:max-w-xl bg-white rounded-md lg:shadow-md w-full pb-6 flex flex-col items-center justify-center">
          {!isVerified ? (
            <div className="mt-4 px-6 pb-6">
              {loading ? (
                <div className="flex items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <div className="w-full">
                  <h1 className="lg:text-2xl font-bold mb-4 text-[#fff] bg-[#245FAF] text-center py-2 lg:py-4 mt-4 lg:mt-0">
                    QR Code Validation
                  </h1>
                  <img src={otpImage} alt="otp_image" />
                  <h3 className="mb-2 lg:mb-0 text-red-600 text-center font-semibold">
                    OTP Validation is Required.
                  </h3>

                  <div className="flex justify-between sm:gap-6 gap-3 mt-5 w-full mb-4">
                    {otp.map((data, index) => (
                      <input
                        key={index}
                        className="shadow appearance-none border rounded w-full text-gray-700 py-2  text-center leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        maxLength="1"
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onFocus={(e) => e.target.select()}
                      />
                    ))}
                  </div>
                  <div className="w-full flex justify-center">
                    <button
                      onClick={handleOTPInput}
                      className="bg-[#245FAF] p-2 w-full text-white font-bold"
                      disabled={enterLoading}
                    >
                      {enterLoading ? "Loading..." : "Enter"}
                    </button>
                  </div>
                </div>
              )}

              <p
                onClick={handleResendCode}
                className="font-semibold text-[12px] text-[#245FAF] cursor-pointer mt-4"
              >
                {loading ? (
                  ""
                ) : (
                  <div>{isLoading ? "Requesting OTP..." : "Request OTP?"}</div>
                )}
              </p>
            </div>
          ) : (
            <div className="mt-4 pb- flex flex-col items-center justify-center">
              {!profile && (
                <div>
                  <h2 className="text-md lg:text-md font-bold mb-2 text-[#245FAF] text-center">
                    Click here to view all documents
                  </h2>
                  <button
                    onClick={handleView}
                    className="bg-[#245FAF] p-2 w-full text-white font-bold"
                  >
                    {"View"}
                  </button>
                </div>
              )}

              <ProfileDetails profile={profile} filteredDocs={filteredDocs} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
